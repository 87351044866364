/*Universal css*/
/* Needed for full-screen map */
html,
body,
div#root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 14px; }

body {
  background-image: linear-gradient(#88d1dc, #33a0ab);
  background-attachment: fixed; }

label {
  font-weight: bold; }

.button-link {
  color: #777; }

.logo-border {
  height: 70px;
  margin-top: -45px; }

.right-button {
  float: right; }

/*Custom button */
.custom-btn {
  color: #ffffff;
  background-color: #5eb5bb;
  border-color: #339999; }

.custom-btn:hover,
.custom-btn:focus,
.custom-btn:active,
.custom-btn.active,
.open .dropdown-toggle.custom-btn {
  color: #ffffff;
  background-color: #33a0ab;
  border-color: #339999; }

.custom-btn:active,
.custom-btn.active,
.open .dropdown-toggle.custom-btn {
  background-image: none; }

.custom-btn.disabled,
.custom-btn[disabled],
fieldset[disabled] .custom-btn,
.custom-btn.disabled:hover,
.custom-btn[disabled]:hover,
fieldset[disabled] .custom-btn:hover,
.custom-btn.disabled:focus,
.custom-btn[disabled]:focus,
fieldset[disabled] .custom-btn:focus,
.custom-btn.disabled:active,
.custom-btn[disabled]:active,
fieldset[disabled] .custom-btn:active,
.custom-btn.disabled.active,
.custom-btn[disabled].active,
fieldset[disabled] .custom-btn.active {
  background-color: #5eb5bb;
  border-color: #339999; }

.custom-btn .badge {
  color: #5eb5bb;
  background-color: #ffffff; }

/*end custom button */
/*Grey buttom */
.btn-grey {
  color: #000000;
  background-color: #e7e7e7;
  border-color: #d7d7d7;
  margin-top: 2em;
  margin-bottom: 1em; }

.btn-grey:hover,
.btn-grey:focus,
.btn-grey:active,
.btn-grey.active,
.open .dropdown-toggle.btn-grey {
  color: #000000;
  background-color: #d7d7d7;
  border-color: #d7d7d7; }

.btn-grey:active,
.btn-grey.active,
.open .dropdown-toggle.btn-grey {
  background-image: none; }

.btn-grey.disabled,
.btn-grey[disabled],
fieldset[disabled] .btn-grey,
.btn-grey.disabled:hover,
.btn-grey[disabled]:hover,
fieldset[disabled] .btn-grey:hover,
.btn-grey.disabled:focus,
.btn-grey[disabled]:focus,
fieldset[disabled] .btn-grey:focus,
.btn-grey.disabled:active,
.btn-grey[disabled]:active,
fieldset[disabled] .btn-grey:active,
.btn-grey.disabled.active,
.btn-grey[disabled].active,
fieldset[disabled] .btn-grey.active {
  background-color: #e7e7e7;
  border-color: #d7d7d7; }

.btn-grey .badge {
  color: #e7e7e7;
  background-color: #000000; }

/*end grey buttom */
/* Navbar */
.navbar {
  margin-bottom: 0;
  border-radius: 0 !important;
  border-color: #e7e7e7;
  /* fix for issue where container covers navbar when a scrollbar is present */
  min-height: inherit !important; }

.navbar-brand img {
  height: 50px;
  display: inline;
  float: left;
  padding: 5px 0px 5px; }

.control-label {
  padding-top: 0.4em;
  font-size: 16px; }

/*  Containers */
.container-div {
  background-color: #fff;
  margin-top: 8em;
  padding: 0em 5em 4em;
  border-radius: 0.7em;
  margin-bottom: 2em; }

.container-div .row {
  padding-top: 1em; }

.container-div .row .form-control {
  font-size: 16px; }

.version-text {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #256b6b;
  font-size: 10px; }

.beta-version-text {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #d9534f;
  font-size: 12px;
  font-weight: bold; }
