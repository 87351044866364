.editGridHeader {
  background-color: #337ab7;
  color: #fff; }

.editGridHeadertwo {
  background-color: #337ab7;
  color: #fff; }

.editGridHeaderthree {
  background-color: #337ab7;
  color: #fff;
  padding-top: 6px; }

.btn-primary {
  border-color: #337ab7 !important; }
