.inspection-config-select-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.inspection-config-form-wrapper {
  flex: 1; }

.inspection-config-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 1rem; }
