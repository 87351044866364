.dataGridContainer {
  display: flex;
  width: 100vw;
  height: calc(100% - 8em);
  position: absolute;
  left: 0;
  margin-top: 8em; }

.whiteBox {
  background-color: white;
  border-radius: 0.7em;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 1.5em 1.5em 1.5em; }

.whitebox-child-wrapper {
  flex: 1; }

.scrollable {
  overflow-y: auto; }
