.idgFlexContainer {
  height: 100%;
  display: flex;
  flex-direction: column; }

.idgAutoSizeWrapper {
  margin-top: 8px;
  flex: 1;
  display: flex; }

.idgHeader {
  background-color: #339999;
  color: white;
  font-weight: bold; }

.idgHeader div:hover {
  height: auto; }

.idgDataRow {
  background-color: #ececec; }
  .idgDataRow:hover {
    background-color: white;
    z-index: 1;
    white-space: normal;
    overflow: visible; }

.idgDataRow div:hover {
  z-index: 1;
  background-color: white;
  overflow: visible; }

.idgDataRowDark {
  background-color: #bcbcbc; }
  .idgDataRowDark:hover {
    background-color: white;
    z-index: 1;
    white-space: normal;
    overflow: visible; }

.idgDataRowDark div:hover {
  z-index: 1;
  background-color: white;
  overflow: visible; }

.DateRangePicker_picker {
  z-index: 2; }

.inspection-count {
  text-align: left;
  font-weight: 600;
  font-size: 1.5em;
  padding-left: 0.5rem; }
