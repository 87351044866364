.panel--sorting {
  border-color: #00acc1; }
  .panel--sorting:hover {
    cursor: move; }
  .panel--sorting:active {
    cursor: move;
    border: 3px dodgerblue; }

pre {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px; }
