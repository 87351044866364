.public_fixedDataTable_bodyRow:hover,
.fixedDataTableCellGroupLayout_cellGroup:hover,
.public_fixedDataTableCell_main:hover {
  overflow: visible; }

.fixedDataTableRowLayout_rowWrapper:hover,
.public_fixedDataTableCell_main:hover {
  z-index: 1 !important; }

.public_fixedDataTable_bodyRow .public_fixedDataTableCell_cellContent:hover {
  background-color: lightblue; }

.public_fixedDataTable_main {
  margin: 0 auto; }
